/* stylelint-disable declaration-no-important */
@use '@angular/material' as mat;

/* ------------------------------------ *\
    #Material
\* ------------------------------------ */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rundp-theme);

.high-density {
  @include mat.form-field-density(-2.5);

  &.show-label .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
    display: initial;
  }
}

.density-1 {
  @include mat.form-field-density(-1);
}

.density-2 {
  @include mat.form-field-density(-2);
}

.density-3 {
  @include mat.form-field-density(-3);
}

// Fix mat-button contrast not applied https://github.com/angular/components/issues/26056
.mat-mdc-raised-button.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  color: mat.get-color-from-palette($rundp-primary, default-contrast) !important;

  --mat-mdc-button-persistent-ripple-color: mat.get-color-from-palette($rundp-primary, default-contrast) !important;
  --mat-mdc-button-ripple-color: rgb(255 255 255 / 10%); // Insert custom rgba contrast color
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-icon-color: mat.get-color-from-palette($rundp-primary, default-contrast);
  --mat-mdc-fab-color: mat.get-color-from-palette($rundp-primary, default-contrast);
}

.mat-mdc-raised-button.mat-accent,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  color: mat.get-color-from-palette($rundp-accent, default-contrast) !important;

  --mat-mdc-button-persistent-ripple-color: mat.get-color-from-palette($rundp-accent, default-contrast) !important;
  --mat-mdc-button-ripple-color: rgb(255 255 255 / 10%); // Insert custom rgba contrast color
}

.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-icon-color: mat.get-color-from-palette($rundp-accent, default-contrast);
  --mat-mdc-fab-color: mat.get-color-from-palette($rundp-accent, default-contrast);
}

// Fix checkbox contrast not applied
* {
  --mdc-checkbox-selected-checkmark-color: #fff !important; // Using mat.get-color-from-palette() doesn't work
}
