@import '../../../libs/rundp-components/scss/variables';
@import '../../../libs/rundp-components/scss/base';
@import '../../../libs/rundp-components/scss/global';
@import '../../../libs/rundp-components/scss/vendor';

body {
  background: #f7f7f7;
}

.table-shadow {
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 1px 0 rgb(0 0 0 / 20%);
}

.subview-container {
  padding: 20px 30px;
}

.subview-header-row {
  --subview-header-padding-bottom: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(var(--mdc-outlined-button-container-height) + var(--subview-header-padding-bottom));
  padding-bottom: var(--subview-header-padding-bottom);

  h3 {
    margin: 0;

    color: #202020;
    font-size: 20px;
    font-weight: 500;
  }
}

.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
