/* stylelint-disable declaration-no-important */
@use 'sass:map';

th {
  font-size: 12px;
  font-style: normal;

  // fixme remove !important
  font-weight: bold !important;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.4px;
}

table a {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  width: 248px;
  height: 28px;

  color: #ef7c00;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  text-decoration-line: underline;
}

mat-checkbox {
  border: 2px solid;

  color: transparent;
  accent-color: #bcbcbc;
}

.mat-mdc-snack-bar-container {
  &.rundp-snackbar {
    --mdc-snackbar-container-color: #fff;
    --mat-mdc-snack-bar-button-color: var(--primary-700);
    --mdc-snackbar-supporting-text-color: #000;
  }
}

.nav-bar-button {
  display: flex;
  align-items: center;

  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
}

.mat-mdc-form-field-error {
  display: block;
  margin: 0 0 0 -19px;
}

.form-row-container {
  display: grid;
  align-items: center;
  grid-template-columns: 150px 1fr;
  padding: 1rem 0;

  border-bottom: 1px solid #9d9d9d;

  mat-form-field {
    margin-bottom: 0;
  }
}
