/* stylelint-disable custom-property-pattern */
@forward 'sass:map';
@forward '@angular/material';
@use 'sass:map';
@use '@angular/material' as mat;

:root {
  // Primary colors
  --primary-50: #fef3e0;
  --primary-100: #fddfb2;
  --primary-200: #fccb80;
  --primary-300: #fab64d;
  --primary-400: #faa627;
  --primary-500: #f99702;
  --primary-600: #f58b01;
  --primary-700: #ef7c00;
  --primary-800: #e96c00;
  --primary-900: #e05200;
  --primary-A100: #fff;
  --primary-A200: #ffe2d8;
  --primary-A400: #ffbda5;
  --primary-A700: #ffaa8b;
  --primary-contrast-50: #000;
  --primary-contrast-100: #000;
  --primary-contrast-200: #000;
  --primary-contrast-300: #fff;
  --primary-contrast-400: #fff;
  --primary-contrast-500: #fff;
  --primary-contrast-600: #fff;
  --primary-contrast-700: #fff;
  --primary-contrast-800: #fff;
  --primary-contrast-900: #fff;
  --primary-contrast-A100: #000;
  --primary-contrast-A200: #000;
  --primary-contrast-A400: #000;
  --primary-contrast-A700: #000;

  // Accent colors
  --accent-50: #fafafa;
  --accent-100: #f5f5f5;
  --accent-200: #ededed;
  --accent-300: #dfdfdf;
  --accent-400: #bcbcbc;
  --accent-500: #9d9d9d;
  --accent-600: #747474;
  --accent-700: #606060;
  --accent-800: #414141;
  --accent-900: #202020;
  --accent-A100: #fff;
  --accent-A200: #f2f4ff;
  --accent-A400: #bfc9ff;
  --accent-A700: #a6b4ff;
  --accent-contrast-50: #000;
  --accent-contrast-100: #000;
  --accent-contrast-200: #000;
  --accent-contrast-300: #fff;
  --accent-contrast-400: #fff;
  --accent-contrast-500: #fff;
  --accent-contrast-600: #fff;
  --accent-contrast-700: #fff;
  --accent-contrast-800: #fff;
  --accent-contrast-900: #fff;
  --accent-contrast-A100: #000;
  --accent-contrast-A200: #000;
  --accent-contrast-A400: #000;
  --accent-contrast-A700: #000;

  // Warn colors
  --warn-50: #ffebee;
  --warn-100: #ffcdd2;
  --warn-200: #ef9a9a;
  --warn-300: #e57373;
  --warn-400: #ef5350;
  --warn-500: #f44336;
  --warn-600: #e53935;
  --warn-700: #d32f2f;
  --warn-800: #c62828;
  --warn-900: #b71c1c;
  --warn-A100: #ff8a80;
  --warn-A200: #ff5252;
  --warn-A400: #ff1744;
  --warn-A700: #d50000;
  --warn-contrast-50: #000;
  --warn-contrast-100: #000;
  --warn-contrast-200: #000;
  --warn-contrast-300: #000;
  --warn-contrast-400: #fff;
  --warn-contrast-500: #fff;
  --warn-contrast-600: #fff;
  --warn-contrast-700: #fff;
  --warn-contrast-800: #fff;
  --warn-contrast-900: #fff;
  --warn-contrast-A100: #000;
  --warn-contrast-A200: #fff;
  --warn-contrast-A400: #fff;
  --warn-contrast-A700: #fff;
}

/* ------------------------------------ *\
    #General Variables
\* ------------------------------------ */

$rundp-font-family: 'Roboto', 'Helvetica Neue', sans-serif;
$html-background: #fcfcfc;

/* ------------------------------------ *\
    #Material
\* ------------------------------------ */

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rundp-primary: mat.define-palette(
  (
    50: var(--primary-50),
    100: var(--primary-100),
    200: var(--primary-200),
    300: var(--primary-300),
    400: var(--primary-400),
    500: var(--primary-500),
    600: var(--primary-600),
    700: var(--primary-700),
    800: var(--primary-800),
    900: var(--primary-900),
    A100 : var(--primary-A100),
    A200 : var(--primary-A200),
    A400 : var(--primary-A400),
    A700 : var(--primary-A700),
    contrast: (
      50 : var(--primary-contrast-50),
      100 : var(--primary-contrast-100),
      200 : var(--primary-contrast-200),
      300 : var(--primary-contrast-300),
      400 : var(--primary-contrast-400),
      500 : var(--primary-contrast-500),
      600 : var(--primary-contrast-600),
      700 : var(--primary-contrast-700),
      800 : var(--primary-contrast-800),
      900 : var(--primary-contrast-900),
      A100 : var(--primary-contrast-A100),
      A200 : var(--primary-contrast-A200),
      A400 : var(--primary-contrast-A400),
      A700 : var(--primary-contrast-A700),
    )
  ),
  700,
  300,
  800
);
$rundp-accent: mat.define-palette(
  (
    50: var(--accent-50),
    100: var(--accent-100),
    200: var(--accent-200),
    300: var(--accent-300),
    400: var(--accent-400),
    500: var(--accent-500),
    600: var(--accent-600),
    700: var(--accent-700),
    800: var(--accent-800),
    900: var(--accent-900),
    A100 : var(--accent-A100),
    A200 : var(--accent-A200),
    A400 : var(--accent-A400),
    A700 : var(--accent-A700),
    contrast: (
      50 : var(--accent-contrast-50),
      100 : var(--accent-contrast-100),
      200 : var(--accent-contrast-200),
      300 : var(--accent-contrast-300),
      400 : var(--accent-contrast-400),
      500 : var(--accent-contrast-500),
      600 : var(--accent-contrast-600),
      700 : var(--accent-contrast-700),
      800 : var(--accent-contrast-800),
      900 : var(--accent-contrast-900),
      A100 : var(--accent-contrast-A100),
      A200 : var(--accent-contrast-A200),
      A400 : var(--accent-contrast-A400),
      A700 : var(--accent-contrast-A700),
    )
  )
);

// The warn palette is optional (defaults to red).
$rundp-warn: mat.define-palette(
  (
    50: var(--warn-50),
    100: var(--warn-100),
    200: var(--warn-200),
    300: var(--warn-300),
    400: var(--warn-400),
    500: var(--warn-500),
    600: var(--warn-600),
    700: var(--warn-700),
    800: var(--warn-800),
    900: var(--warn-900),
    A100 : var(--warn-A100),
    A200 : var(--warn-A200),
    A400 : var(--warn-A400),
    A700 : var(--warn-A700),
    contrast: (
      50 : var(--warn-contrast-50),
      100 : var(--warn-contrast-100),
      200 : var(--warn-contrast-200),
      300 : var(--warn-contrast-300),
      400 : var(--warn-contrast-400),
      500 : var(--warn-contrast-500),
      600 : var(--warn-contrast-600),
      700 : var(--warn-contrast-700),
      800 : var(--warn-contrast-800),
      900 : var(--warn-contrast-900),
      A100 : var(--warn-contrast-A100),
      A200 : var(--warn-contrast-A200),
      A400 : var(--warn-contrast-A400),
      A700 : var(--warn-contrast-A700),
    )
  )
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rundp-theme: mat.define-light-theme(
  (
    color: (
      primary: $rundp-primary,
      accent: $rundp-accent,
      warn: $rundp-warn,
    ),
    typography: mat.define-typography-config(
      $font-family: 'Roboto',
    ),
  )
);
$rundp-color-config: mat.get-color-config($rundp-theme);
$rundp-primary-pal: map.get($rundp-color-config, 'primary');
$rundp-accent-pal: map.get($rundp-color-config, 'accent');
