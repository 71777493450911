html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;

  font-family: $rundp-font-family;
}

mat-icon {
  font-variation-settings: 'FILL' 1, 'GRAD' 200;
}

/* ------------------------------------ *\
    #Fonts
\* ------------------------------------ */

@font-face {
  font-family: Roboto;
  src:
    url('/assets/fonts/Roboto-Flex.woff2') format('woff2') tech('variations'),
    url('/assets/fonts/Roboto-Flex.woff2') format('woff2-variations');
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

/* @font-face {
  font-family: 'Material Icons';
  src: url('../apps/rundp/src/assets/') format('woff2');
  font-style: normal;
  font-weight: 400;
}
*/
